<template>
  <q-dialog v-model="visible" full-height position="right">
    <q-card style="width: 1000px; max-width: 90vw">
      <q-toolbar>
        <q-toolbar-title> Tag </q-toolbar-title>

        <q-btn flat round dense icon="close" v-close-popup />
      </q-toolbar>
      <q-card-section>
        <q-form ref="formRef" greedy>
          <div class="max-height-dialog">
            <TagCode :trackingCode="trackingCode" :tagJsUrl="campaignTagJsUrl"></TagCode>
          </div>

          <div class="row no-wrap items-center">
            <q-space />
            <div class="q-gutter-sm">
              <q-btn no-caps flat color="white" text-color="black" @click="onCancel">{{ $t('cancel') }}</q-btn>
            </div>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import TagCodeSingle from '@/components/common/ui/TagCodeSingle.vue'
import TagCode from '@/components/common/ui/TagCode.vue'

@Options({
  components: { TagCodeSingle, TagCode },
  directives: { maska },
  emits: ['update:closeModal', 'update:onSelect'],
})
export default class TagCodeModal extends Vue {
  @Prop()
  modalVisible!: boolean

  @Prop()
  trackingCode!: string

  @Prop()
  campaignTagJsUrl!: string

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  onCancel() {
    this.visible = false
  }
}
</script>
