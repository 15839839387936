<template>
  <CLoading :loading="loading" />
  <div class="q-mb-md">
    <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
  </div>
  <q-table
    bordered
    flat
    dense
    title="Treats"
    ref="welcomeList"
    v-if="targetSettings"
    :rows="targetSettings"
    row-key="title"
    :rows-per-page-options="[10]"
    separator="cell"
    :no-data-label="$t('messages.no_data_available')"
    class="text-grey-8"
  >
    <template v-slot:header="props">
      <q-tr>
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.prod') }}</span></q-th
        >
        <q-th rowspan="2" key="action" class="text-center" style="width: 50px"
          ><span class="text-bold">{{ $t('label.test') }}</span></q-th
        >
        <q-th rowspan="2" key="action" class="text-center" style="width: 100px"></q-th>
        <q-th :props="props" rowspan="2" key="target_name">
          <span class="text-bold">{{ $t('label.target_setting.target_name') }}</span>
        </q-th>
      </q-tr>
    </template>
    <template v-slot:body="props">
      <q-tr :props="props" :index="props.rowKey">
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_active"
            val="props.row.is_active"
            @click="handleToggleActive(props.row)"
          />
        </q-td>
        <q-td>
          <q-toggle
            round
            v-model="props.row.is_test_mode"
            val="props.row.is_test_mode"
            @click="handleToggleTest(props.row)"
          />
        </q-td>
        <q-td key="action" :props="props.index">
          <q-btn size="sm" round outline icon="code" @click="handleDisplayTagCodeModal(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline icon="edit" @click="onEdit(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="grey" icon="content_copy" @click="onCopy(props.row)" class="q-mr-sm" />
          <q-btn size="sm" round outline color="red" icon="delete" @click="onDelete(props.row)" class="q-mr-sm" />
        </q-td>
        <q-td key="target_name" :props="props">
          {{ props.row.target_name }}
        </q-td>
      </q-tr>
    </template>

    <template v-slot:top separator>
      <h2 class="text-h6 text-black">{{ $t('label.target_setting.target_setting_list') }}</h2>
      <q-space />
      <q-btn no-caps color="primary" @click="onAdd(0)" :label="$t('add_new')" />
    </template>
  </q-table>

  <TargetSettingFormModal
    v-if="modalVisible"
    :modalVisible="modalVisible"
    :data="selectedTargetSetting"
    @update:closeModal="onCloseModal"
  />
  <TagCodeModal
    v-if="tagCodeModalVisible"
    :modalVisible="tagCodeModalVisible"
    :trackingCode="trackingCode"
    :campaignTagJsUrl="successTagJsUrl"
    @update:closeModal="onCloseTagCodeModal"
  />
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ITargetSetting } from '@/utils/types'
import { Watch } from 'vue-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import { ACTION_CAMPAIGN, ACTION_TARGET_SETTING } from '@/store/actions'
import CLoading from '@/components/common/ui/CLoading.vue'
import TargetSettingFormModal from '@/components/target-setting/TargetSettingFormModal.vue'
import TagCodeModal from '@/components/common/ui/TagCodeModal.vue'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'

@Options({
  components: { CLoading, TargetSettingFormModal, TagCodeModal, TestModeSelector },
  directives: { maska },
  emits: ['update:changeTab'],
})
export default class TargetSettingList extends mixins(BaseFormMixin) {
  loading = true
  modalVisible = false
  tagCodeModalVisible = false
  selectedTargetSetting: ITargetSetting = {}
  isTestMode = false

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get targetSettings() {
    return cloneDeep(this.$store.getters.targetSettings) || []
  }

  get successTagJsUrl(): boolean {
    return this.$store.getters.successTagjsUrl
  }

  get trackingCode() {
    return this.selectedTargetSetting._id + ';' + this.isTestMode + ';' + this.selectedAppId
  }

  showAudienceType(type: string) {
    if (type === 'initial') {
      return this.$t('label.audience.audience_type_initial')
    } else {
      return this.$t('label.audience.audience_type_push')
    }
  }

  @Watch('selectedAppId', { immediate: true })
  async appIdChanged() {
    if (!this.selectedAppId) {
      return
    }

    await this.$store.dispatch(ACTION_TARGET_SETTING.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })
    await this.$store.dispatch(ACTION_CAMPAIGN.LOADED_TAG_JS)
    this.loading = false
  }

  async updateIsActive(item: ITargetSetting) {
    const isSuccess = await this.$store.dispatch(ACTION_TARGET_SETTING.IS_ACTIVE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async updateTestMode(item: ITargetSetting) {
    const isSuccess = await this.$store.dispatch(ACTION_TARGET_SETTING.TEST_MODE, item)
    if (isSuccess) {
      this.$q.notify({
        message: this.$t('messages.saved'),
        color: 'positive',
      })
    }

    return isSuccess
  }

  async handleToggleActive(record: ITargetSetting) {
    const menu = this.targetSettings.find((item) => item._id !== record._id && item.is_active === true)
    if (!menu) {
      if (!record.is_active) {
        this.updateIsActive(record)
      } else {
        this.$q
          .dialog({
            title: this.$t('messages.confirm'),
            message: this.$t('messages.are_you_sure_to_turn_on_active_for_this_item'),
            cancel: {
              flat: true,
              label: this.$t('messages.no'),
            },
            ok: {
              flat: true,
              label: this.$t('messages.yes'),
            },
            persistent: true,
          })
          .onOk(async () => {
            this.updateIsActive(record)
          })
          .onCancel(async () => {
            record.is_active = false
          })
      }
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          menu.is_active = false
          this.updateIsActive(menu)
        })

      record.is_active = false
    }
  }

  async handleToggleTest(record: ITargetSetting) {
    const target = this.targetSettings.find((item) => item._id !== record._id && item.is_test_mode === true)
    if (!target) {
      this.updateTestMode(record)
    } else {
      this.$q
        .dialog({
          title: this.$t('messages.confirm'),
          message: this.$t('messages.please_diasble_all_before_this_feature_in_enabled'),
          cancel: {
            flat: true,
            label: this.$t('cancel'),
          },
          persistent: true,
        })
        .onOk(async () => {
          target.is_test_mode = false
          this.updateTestMode(target)
        })

      record.is_test_mode = false
    }
  }

  onAdd() {
    this.selectedTargetSetting = {
      app_id: this.selectedAppId,
    }

    this.modalVisible = true
  }

  onCopy(data: ITargetSetting) {
    const targetCopy: ITargetSetting = cloneDeep(data)
    targetCopy._id = ''
    targetCopy.is_active = false
    targetCopy.is_test_mode = false
    targetCopy.target_name = targetCopy.target_name + ' Copy'
    this.selectedTargetSetting = targetCopy
    this.modalVisible = true
  }

  onEdit(data: ITargetSetting) {
    this.selectedTargetSetting = data
    this.modalVisible = true
  }

  async onDelete(data: ITargetSetting) {
    this.$q
      .dialog({
        title: this.$t('confirm'),
        message: this.$t('are_you_sure_you_want_to_delete'),
        cancel: true,
        persistent: true,
      })
      .onOk(async () => {
        const success = await this.$store.dispatch(ACTION_TARGET_SETTING.DELETE, data._id)
        if (success) {
          this.$q.notify({
            message: this.$t('messages.deleted'),
            color: 'positive',
          })
        }
      })
  }

  onCloseModal() {
    this.modalVisible = false
    this.selectedTargetSetting = {}
  }

  handleDisplayTagCodeModal(data: ITargetSetting) {
    this.selectedTargetSetting = data
    this.tagCodeModalVisible = true
  }

  onCloseTagCodeModal() {
    this.tagCodeModalVisible = false
    this.selectedTargetSetting = {}
  }
}
</script>
<style lang="sass" scoped></style>
